html, body {
  width: 100%;
}

html.disable-scroll, body.disable-scroll {
  height: 100%;
  position: fixed;
  overflow: hidden;
}

body {
  color: #676a6c;
  font-family: Roboto, sans-serif;
}

*, h1, h2, h3, h4, h5, li, p {
  font-weight: 300;
}

a, a:visited, a:active {
  color: #1ab394;
}

a:hover {
  color: #14866f;
}

p {
  color: #333;
  font-size: 15px;
  font-weight: 300;
}

h1 {
  margin-top: 10px;
  font-size: 30px;
}

h1.navy {
  font-size: 22px;
}

h2 {
  margin-top: 0;
  font-size: 25px;
}

.navy {
  color: #1ab394;
}

.img-circle {
  -webkit-box-shadow: 3px 4px 5px -2px #0000008c;
  -moz-box-shadow: 3px 4px 5px -2px #0000008c;
  box-shadow: 3px 4px 5px -2px #0000008c;
}

#modal {
  visibility: hidden;
  z-index: 300;
  background-color: #000c;
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
}

#modal.slideInLeft {
  left: -100%;
}

#modal.fadeInScale {
  opacity: 0;
  transform: scale(.8);
}

#modal .close-modal {
  cursor: pointer;
  z-index: 200;
  box-sizing: border-box;
  background: #aaa;
  border: 1px solid #eee;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 10px;
  right: 10px;
}

#modal .close-modal:hover {
  background: #919191;
}

#modal .close-modal i {
  color: #fff;
  text-align: center;
  margin: 0 auto;
  font-size: 34px;
  display: block;
  position: relative;
  top: 2px;
  overflow: hidden;
}

#modal .body {
  box-sizing: border-box;
  background-color: #fff;
  width: 100%;
  padding: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

#modal .body .logo {
  width: 22%;
  display: block;
}

#modal .body.elevated {
  width: calc(100% - 40px);
  top: 20px;
  bottom: 20px;
  left: 20px;
  box-shadow: 3px 4px 5px -2px #000000f2;
}

#modal .body p {
  font-size: 25px;
}

header .branding {
  visibility: visible;
  width: 300px;
  animation-name: fadeInUp;
  position: absolute;
  top: 130px;
}

header .branding .headshot {
  width: 70%;
  margin: 0 auto;
  display: block;
}

header .branding .logo {
  width: 100%;
  margin-top: 24px;
  display: block;
}

header .container {
  position: relative;
}

header .container .social {
  position: absolute;
  top: 20px;
  right: 0;
}

header .container .social i, header .container .social svg {
  font-size: 25px;
}

header .container .social a {
  color: #eee;
  margin-left: 13px;
}

header .container .social a:hover {
  color: #ccc;
}

section {
  margin-bottom: 50px;
}

section .header {
  text-align: center;
  margin-bottom: 40px;
}

section .header .red-line {
  border-bottom: 1px solid red;
  width: 60px;
  height: 1px;
  margin: 60px auto 0;
}

section .header .navy-line {
  text-align: center;
  border-bottom: 1px solid #1ab394;
  width: 140px;
  height: 1px;
  margin: 15px auto 0;
  display: none;
}

section.gray {
  background: #f6f6f6;
  margin-bottom: 0;
}

section#summary .blurb-bottom {
  margin-top: 40px;
}

section.experience {
  color: #fff;
  text-align: center;
  background-color: #bbb;
  background-image: url("avatar_all.3b2244b0.png");
  margin-bottom: 0;
  padding-top: 60px;
  padding-bottom: 80px;
}

section.experience h1 {
  text-shadow: 2px 2px 4px #333;
  margin-top: 15px;
}

section.skills ul {
  background-color: #fff;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

section.skills ul.technologies li, section.skills ul.soft-skills li {
  border-left: 3px solid #1ab394;
}

section.skills ul li {
  color: inherit;
  background: #f6f6f6;
  border: 1px solid #eee;
  border-left: 3px solid red;
  margin: 0 0 9px;
  padding: 10px;
  box-shadow: 3px 3px 1px -2px #00000026;
}

section.skills .skill-container {
  background: #f6f6f6;
  border-radius: 13px;
  margin-top: 30px;
  padding: 10px;
  -webkit-box-shadow: 3px 4px 5px -2px #00000059;
  -moz-box-shadow: 3px 4px 5px -2px #00000059;
  box-shadow: 3px 4px 5px -2px #00000059;
}

section.skills .skill-container h2 {
  margin: 0;
}

section.skills .skill-container ul.skills {
  margin: 10px 0 0 20px;
  padding: 0;
  list-style-type: none;
}

section.skills .skill-container ul.skills li {
  margin-top: 5px;
}

section.skills .skill-container ul.skills li:first-child {
  margin-top: 0;
}

section#network .img-small {
  width: 85px;
}

section#contact {
  background-image: url("word_map.4764a7b1.png");
  background-position: 50%;
  background-repeat: no-repeat;
  padding-bottom: 130px;
  overflow: hidden;
}

section#contact .social {
  margin-top: 25px;
}

section#contact .social a {
  margin-right: 46px;
}

section#contact .social a:last-child {
  margin-right: 0;
}

section#contact .social i.twitter {
  position: relative;
  top: 3px;
}

.feature-blurb svg {
  margin: 0 auto 14px;
  display: block;
}

.logos img {
  width: 100px;
}

.header-back {
  background: #f6f6f6 url("header_two.a46428b4.jpg") 50% 0 no-repeat;
  width: 100%;
  height: 470px;
}

.features-icon {
  color: #1ab394;
  font-size: 40px;
}

.team-member {
  text-align: center;
}

@media (width <= 414px) {
  .team-member {
    margin-bottom: 35px;
  }
}

.team-member img {
  margin: auto;
}

.big-icon {
  font-size: 56px;
}

.features .big-icon {
  color: #1ab394;
}

.btn-primary.btn-outline:hover, .btn-success.btn-outline:hover, .btn-info.btn-outline:hover, .btn-warning.btn-outline:hover, .btn-danger.btn-outline:hover {
  color: #fff;
}

.btn-primary {
  color: #fff;
  background-color: #1ab394;
  border-color: #1ab394;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #18a689;
  border-color: #18a689;
}

.btn-primary:active, .btn-primary.active, .open .dropdown-toggle.btn-primary {
  background-image: none;
}

.btn-primary.disabled, .btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled:active, .btn-primary.disabled.active, .btn-primary[disabled], .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled]:active, .btn-primary.active[disabled], fieldset[disabled] .btn-primary, fieldset[disabled] .btn-primary:hover, fieldset[disabled] .btn-primary:focus, fieldset[disabled] .btn-primary:active, fieldset[disabled] .btn-primary.active {
  background-color: #1dc5a3;
  border-color: #1dc5a3;
}

#testimonials {
  padding-bottom: 60px;
}

#testimonials .bubble {
  -webkit-box-shadow: 3px 4px 5px -2px #00000059;
  -moz-box-shadow: 3px 4px 5px -2px #00000059;
  box-shadow: 3px 4px 5px -2px #00000059;
}

.comments-avatar {
  margin-top: 25px;
  margin-left: 22px;
}

.comments-avatar .commens-name {
  font-size: 14px;
  font-weight: 600;
}

.comments-avatar img {
  border-radius: 50%;
  width: 42px;
  height: 42px;
  margin-right: 10px;
}

.bubble {
  background: #fff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  height: auto;
  padding: 20px;
  font-size: 14px;
  font-style: italic;
  position: relative;
}

.bubble:after {
  content: "";
  z-index: 1;
  border: 14px solid #0000;
  border-top: 15px solid #fff;
  border-bottom: 0 solid #fff;
  width: 0;
  display: block;
  position: absolute;
  bottom: -13px;
  left: 30px;
}

@media (width <= 1199px) {
  #testimonials .col-lg-4 {
    margin-top: 50px;
  }

  #testimonials .col-lg-4:first-child {
    margin-top: 0;
  }
}

@media (width <= 768px) {
  a:hover {
    color: #1ab394;
  }

  .img-responsive {
    margin: 0 auto;
    width: 65% !important;
  }

  #modal .body .logo {
    width: 42%;
  }

  header .social {
    display: none;
  }

  header .branding {
    width: 200px;
    top: 230px;
  }

  header .branding .headshot {
    width: 70%;
  }

  header .branding .logo {
    width: 100%;
  }

  section#quotes img {
    float: none;
    margin: 0 auto 10px;
    display: block;
  }

  section.skills .skill-container {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background: inherit;
    margin-top: 0;
    padding: 10px 20px;
  }

  section.skills .skill-container:last-child {
    margin-bottom: 20px;
  }

  section#testimonials .col-lg-4 {
    margin-top: 50px;
  }

  section#testimonials .col-lg-4:first-child {
    margin-top: 0;
  }

  section#contact {
    padding-bottom: 60px;
  }

  section#contact .social {
    margin-top: 25px;
  }

  section#contact .social a {
    margin-right: 25px;
  }

  section#contact .social a:last-child {
    margin-right: 0;
  }
}

@media (width <= 414px) {
  #modal .body .logo {
    width: 60%;
  }

  header .branding {
    width: 80%;
    margin: 0 auto;
    display: block;
    position: relative;
    top: 135px;
  }

  .mobile-pad-top {
    margin-top: 40px;
  }

  section#quotes {
    padding-bottom: 47px;
  }

  section#quotes i {
    display: none;
  }

  section#network .sf {
    margin: 30px 0;
  }

  section#contact svg.arrow-up {
    font-size: 28px;
  }

  section#contact .social {
    margin-top: 0;
  }

  section#contact .social a svg {
    font-size: 33px;
  }
}

p.lead {
  margin-bottom: 10px;
}

.feature-blurb p {
  margin-bottom: 0;
  font-size: 17px;
  line-height: 23px;
}

.background p {
  font-size: 1.4em;
}

section#skills ul li {
  font-size: 16px;
}

@media (width <= 414px) {
  .list-container {
    margin-bottom: 10px;
  }

  .list {
    float: none !important;
    width: 100% !important;
  }

  .list ul:first-child {
    margin-bottom: 0;
  }

  .background p {
    font-size: 1.2em;
  }

  header .social {
    display: block !important;
    right: 16px !important;
  }
}

section.footer {
  position: relative;
}

a#scrollUp {
  color: #888;
}

a#scrollUp:hover {
  color: #6f6f6f;
}

svg.arrow-up {
  font-size: 50px;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.brands {
  opacity: .6;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
}

@media (width <= 414px) {
  .brands {
    display: none;
  }
}

.brands svg {
  width: 100%;
  max-height: 180px;
}

.st0 {
  fill: #4285f4;
}

.st1 {
  fill: #ea4335;
}

.st2 {
  fill: #fbbc05;
}

.st3 {
  fill: #34a853;
}

.st8 {
  fill: #d81921;
}

.st9 {
  fill: #fff;
}

.st20 {
  fill: #33629a;
}
/*# sourceMappingURL=index.d7b37fe2.css.map */
