html,
body {
  width: 100%;
}
html.disable-scroll,
body.disable-scroll {
  overflow: hidden;
  position: fixed;
  height: 100%;
}
body {
  color: #676a6c;
  font-family: "Roboto", sans-serif;
}

* {
  font-weight: 300;
}

h1,
h2 {
  font-weight: 300;
}

h3,
h4,
h5,
li,
p {
  font-weight: 300;
}

a {
  color: #1ab394;
}
a:visited,
a:active {
  color: #1ab394;
}
a:hover {
  color: darken(#1ab394, 10%);
}
p {
  color: #333;
  font-size: 15px;
  font-weight: 300;
}
h1 {
  margin-top: 10px;
  font-size: 30px;
}
h1.navy {
  font-size: 22px;
}
h2 {
  font-size: 25px;
  margin-top: 0;
}
.navy {
  color: #1ab394;
}
.img-circle {
  -webkit-box-shadow: 3px 4px 5px -2px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 3px 4px 5px -2px rgba(0, 0, 0, 0.55);
  box-shadow: 3px 4px 5px -2px rgba(0, 0, 0, 0.55);
}
#modal {
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 300;
  overflow: auto;
}
#modal.slideInLeft {
  left: calc(100% * -1);
}
#modal.fadeInScale {
  transform: scale(0.8, 0.8);
  opacity: 0;
}
#modal .close-modal {
  cursor: pointer;
  height: 40px;
  width: 40px;
  background: #aaa;
  z-index: 200;
  position: absolute;
  top: 10px;
  right: 10px;
  border: 1px solid #eee;
  box-sizing: border-box;
}
#modal .close-modal:hover {
  background: #919191;
}
#modal .close-modal i {
  color: white;
  font-size: 34px;
  top: 2px;
  overflow: hidden;
  margin: 0 auto;
  display: block;
  position: relative;
  text-align: center;
}
#modal .body {
  background-color: white;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 20px;
  box-sizing: border-box;
}
#modal .body .logo {
  width: 22%;
  display: block;
}
#modal .body.elevated {
  width: calc(100% - 40px);
  top: 20px;
  bottom: 20px;
  left: 20px;
  box-shadow: 3px 4px 5px -2px rgba(0, 0, 0, 0.95);
}
#modal .body p {
  font-size: 25px;
}
header .branding {
  position: absolute;
  visibility: visible;
  animation-name: fadeInUp;
  top: 130px;
  width: 300px;
}
header .branding .headshot {
  width: 70%;
  margin: 0 auto;
  display: block;
}
header .branding .logo {
  display: block;
  margin-top: 24px;
  width: 100%;
}
header .container {
  position: relative;
}
header .container .social {
  position: absolute;
  right: 0;
  top: 20px;
}
header .container .social i,
header .container .social svg {
  font-size: 25px;
}
header .container .social a {
  color: #eee;
  margin-left: 13px;
}
header .container .social a:hover {
  color: #ccc;
}
section {
  margin-bottom: 50px;
}
section .header {
  margin-bottom: 40px;
  text-align: center;
}
section .header .red-line {
  width: 60px;
  height: 1px;
  margin: 60px auto 0;
  border-bottom: 1px solid red;
}
section .header .navy-line {
  height: 1px;
  border-bottom: 1px solid #1ab394;
  margin: 15px auto 0;
  width: 140px;
  text-align: center;
  display: none;
}
section.gray {
  background: #f6f6f6;
  margin-bottom: 0;
}
section#summary .blurb-bottom {
  margin-top: 40px;
}

section.experience {
  margin-bottom: 0;
  padding-top: 60px;
  padding-bottom: 80px;
  background-color: #bbb;
  background-image: url("../img/avatar_all.png");
  color: white;
  text-align: center;
  h1 {
    text-shadow: 2px 2px 4px #333;
    margin-top: 15px;
  }
}
section.skills ul {
  background-color: white;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
section.skills ul.technologies li {
  border-left: 3px solid #1ab394;
}
section.skills ul.soft-skills li {
  border-left: 3px solid #1ab394;
}
section.skills ul li {
  box-shadow: 3px 3px 1px -2px rgba(0, 0, 0, 0.15);
  color: inherit;
  margin: 0;
  border: 1px solid #eee;
  padding: 10px;
  margin-bottom: 9px;
  background: #f6f6f6;
  border-left: 3px solid red;
}
section.skills .skill-container {
  -webkit-box-shadow: 3px 4px 5px -2px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 3px 4px 5px -2px rgba(0, 0, 0, 0.35);
  box-shadow: 3px 4px 5px -2px rgba(0, 0, 0, 0.35);
  border-radius: 13px;
  background: #f6f6f6;
  padding: 10px;
  margin-top: 30px;
}
section.skills .skill-container h2 {
  margin: 0;
}
section.skills .skill-container ul.skills {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-left: 20px;
  margin-top: 10px;
}
section.skills .skill-container ul.skills li {
  margin-top: 5px;
}
section.skills .skill-container ul.skills li:first-child {
  margin-top: 0;
}
section#network .img-small {
  width: 85px;
}
section#contact {
  background-image: url("../img/word_map.png");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  overflow: hidden;
  padding-bottom: 130px;
}
section#contact .social {
  margin-top: 25px;
}
section#contact .social a {
  margin-right: 46px;
}
section#contact .social a:last-child {
  margin-right: 0;
}
section#contact .social i.twitter {
  position: relative;
  top: 3px;
}

.feature-blurb {
  svg {
    display: block;
    margin: 0 auto 14px;
  }
}
.logos img {
  width: 100px;
}
/* BACKGROUNDS SLIDER
-------------------------------------------------- */
.header-back {
  height: 470px;
  width: 100%;
  background: url("../img/header_two.jpg") 50% 0 no-repeat;
  background-color: #f6f6f6;
}

.features-icon {
  color: #1ab394;
  font-size: 40px;
}
.team-member {
  text-align: center;
  @media (max-width: 414px) {
    margin-bottom: 35px;
  }
}
.team-member img {
  margin: auto;
}
.big-icon {
  font-size: 56px;
}
.features .big-icon {
  color: #1ab394;
}
/* Buttons - only primary custom button
------------------------- */
.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-warning.btn-outline:hover,
.btn-danger.btn-outline:hover {
  color: #fff;
}
.btn-primary {
  background-color: #1ab394;
  border-color: #1ab394;
  color: #ffffff;
  font-size: 14px;
  padding: 10px 20px;
  font-weight: 600;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  background-color: #18a689;
  border-color: #18a689;
  color: #ffffff;
}
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  background-image: none;
}
.btn-primary.disabled,
.btn-primary.disabled:hover,
.btn-primary.disabled:focus,
.btn-primary.disabled:active,
.btn-primary.disabled.active,
.btn-primary[disabled],
.btn-primary[disabled]:hover,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:active,
.btn-primary.active[disabled],
fieldset[disabled] .btn-primary,
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary.active {
  background-color: #1dc5a3;
  border-color: #1dc5a3;
}
#testimonials {
  padding-bottom: 60px;
}
#testimonials .bubble {
  -webkit-box-shadow: 3px 4px 5px -2px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 3px 4px 5px -2px rgba(0, 0, 0, 0.35);
  box-shadow: 3px 4px 5px -2px rgba(0, 0, 0, 0.35);
}
.comments-avatar {
  margin-top: 25px;
  margin-left: 22px;
}
.comments-avatar .commens-name {
  font-weight: 600;
  font-size: 14px;
}
.comments-avatar img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 10px;
}
.bubble {
  position: relative;
  height: auto;
  padding: 20px;
  background: #ffffff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  font-style: italic;
  font-size: 14px;
}
.bubble:after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 15px 14px 0;
  border-color: #ffffff transparent;
  display: block;
  width: 0;
  z-index: 1;
  bottom: -13px;
  left: 30px;
}
@media (max-width: 1199px) {
  #testimonials .col-lg-4 {
    margin-top: 50px;
  }
  #testimonials .col-lg-4:first-child {
    margin-top: 0;
  }
}
@media (max-width: 768px) {
  a:hover {
    color: #1ab394;
  }
  .img-responsive {
    width: 65% !important;
    margin: 0 auto;
  }
  #modal .body .logo {
    width: 42%;
  }
  header .social {
    display: none;
  }
  header .branding {
    top: 230px;
    width: 200px;
  }
  header .branding .headshot {
    width: 70%;
  }
  header .branding .logo {
    width: 100%;
  }
  section#quotes img {
    float: none;
    display: block;
    margin: 0 auto 10px;
  }
  section.skills .skill-container {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background: inherit;
    padding: 10px 20px;
    margin-top: 0;
  }
  section.skills .skill-container:last-child {
    margin-bottom: 20px;
  }
  section#testimonials .col-lg-4 {
    margin-top: 50px;
  }
  section#testimonials .col-lg-4:first-child {
    margin-top: 0;
  }
  section#contact {
    padding-bottom: 60px;
  }
  section#contact .social {
    margin-top: 25px;
  }
  section#contact .social a {
    margin-right: 25px;
  }
  section#contact .social a:last-child {
    margin-right: 0;
  }
}
/* iphone 6+ */
@media (max-width: 414px) {
  #modal .body .logo {
    width: 60%;
  }
  header .branding {
    top: 135px;
    display: block;
    margin: 0 auto;
    width: 80%;
    position: relative;
  }
  .mobile-pad-top {
    margin-top: 40px;
  }
  section#quotes {
    padding-bottom: 47px;
  }
  section#quotes i {
    display: none;
  }
  section#network .sf {
    margin: 30px 0;
  }

  section#contact {
    svg.arrow-up {
      font-size: 28px;
    }
    .social {
      margin-top: 0;
      a {
        svg {
          font-size: 33px;
        }
      }
    }
  }
}

p.lead {
  margin-bottom: 10px;
}

.feature-blurb p {
  font-size: 17px;
  line-height: 23px;
  margin-bottom: 0;
}

.background p {
  font-size: 1.4em;
}

section#skills ul li {
  font-size: 16px;
}

@media (max-width: 414px) {
  .list-container {
    margin-bottom: 10px;
  }

  .list {
    float: none !important;
    width: 100% !important;
  }

  .list ul:first-child {
    margin-bottom: 0;
  }

  .background p {
    font-size: 1.2em;
  }

  header .social {
    display: block !important;
    right: 16px !important;
  }
}

section.footer {
  position: relative;
}

a#scrollUp {
  color: #888;
  &:hover {
    color: darken(#888, 10%);
  }
}

svg.arrow-up {
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 50px;
}

.brands {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  opacity: 0.6;
  @media (max-width: 414px) {
    display: none;
  }
  svg {
    width: 100%;
    max-height: 180px;
  }
}

// brand logo svgs
.st0 {
  fill: #4285f4;
}
.st1 {
  fill: #ea4335;
}
.st2 {
  fill: #fbbc05;
}
.st3 {
  fill: #34a853;
}

.st8 {
  fill: #d81921;
}

.st9 {
  fill: #ffffff;
}

.st20 {
  fill: #33629a;
}
